<template>
  <div>
  <b-modal v-model="ShowMe" has-modal-card :can-cancel="false" full-screen id="adminRemote">

    <div class="modal-card">
      <header class="roundless card-header has-background-primary">
        <p class="card-header-title has-text-white py-2 is-uppercase">{{$t('remote.adminRemote')}}</p>
      </header>
      <section class="modal-card-body remote" ref="adminRemoteModal">
        <div class="mb-3">
          <b-switch size="is-small" class="has-text-weight-bold" v-model="defaultHostControl">
            <d-icon icon="FaUsers" size="is-small" class="pt-1" :class="{ 'has-text-primary': defaultHostControl }" />
            {{ $t('remote.defaultHostControl') }}         
          </b-switch>
        </div>
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="card playlist-card">
              <header class="card-header">
                <p class="card-header-title">
                  <d-icon icon="FaListUl" size="is-small" class="mr-2" />
                  {{$t('remote.playlist')}}
                  {{ this.$store.state.setup.playlist.title }}
                </p>
              </header>
              <div class="card-content">
                <div class="is-flex" style="gap: 0.5em">
                  <b-field class="mb-0" style="width: 100%">
                    <b-select expanded v-model="selectedPlaylist">
                      <option v-for="option in $store.state.admin.playlists" :value="option.id" :key="option.id">
                        {{ option.title }}
                      </option>
                    </b-select>
                  </b-field>
                  <div class="is-flex" style="gap:0.5em">
                    <b-button
                      :label="$t('system.reset')"
                      type="is-primary"
                      outlined
                      expanded
                      @click="ResetPlaylist()" />
                    <b-button
                      :label="$t('system.save')"
                      expanded
                      type="is-primary"
                      @click="ChangePlaylist()" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <admin-remote-card class="column is-half" v-for="room in playlist" :key="room.space.id + room.experience.id" :roomData="room"
            :ref="`adminRemote-${room.space.slug}`"></admin-remote-card>
        </div>

        <div class="columns">
          <div class="column is-half">
            <b-button :label="$t('remote.sendRegistrationForm')" type="is-primary" size="is-small" outlined expanded @click="RequestUsersVerify()" />
          </div>
          <div class="column is-half">
            <b-button :label="$t('remote.sendMessage')" type="is-primary" size="is-small" outlined expanded @click="ShowMessage()"/>
          </div>
        </div>

      </section>
      <footer class="modal-card-foot has-background-white-ter">
        <b-button :label="$t('system.close')" type="is-primary" expanded @click="$store.commit('admin/showRemote', false)" />
      </footer>
    </div>

  </b-modal>
  
    <!-- Close screen dialog  -->
    <b-modal v-model="showMessageDialog" scroll="keep" :can-cancel="false">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-white is-size-5">{{ $t('remote.sendMessage') }}</p>
        </header>
        <section class="modal-card-body has-background-white-ter">
          <b-field :label="$t('remote.message')">
            <b-input v-model="messageText" type="text" expanded></b-input>
          </b-field>

          <b-field :label="$t('remote.type')" class="mb-0">
            <b-select v-model="messageType" expanded>
              <option value="is-primary" selected>
                {{ $t('remote.primary') }}
              </option>
              <option value="is-info">
                {{ $t('remote.info') }}
              </option>
              <option value="is-success">
                {{ $t('remote.success') }}
              </option>
              <option value="is-warning">
                {{ $t('remote.warning') }}
              </option>
              <option value="is-danger">
                {{ $t('remote.danger') }}
              </option>
            </b-select>
          </b-field>
        </section>
        <footer class="modal-card-foot has-background-white-bis is-justify-content-end">
          <b-button
            :label="$t('system.cancel')"
            type="is-primary"
            size="is-small"
            outlined
            expanded
            @click="showMessageDialog = false"/>
            <b-button
            :label="$t('remote.send')"
            :disabled="messageText.trim().length == 0"
            type="is-primary"
            size="is-small"
            expanded
            @click="SendMessage()"/>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import AdminRemoteCard from "@/components/AdminRemoteCard.vue";

export default {
  components: { AdminRemoteCard },
  props: {},
  data() {
    return {
      ShowDialogOverrideExperience: false,
      spaceEditing: null,
      selectedExperience: null,
      selectedPlaylist: this.$store.state.setup.playlist.id,
      changingVolumeItem: null,
      showDialogChangeVolume: false,
      newVolumeValue: 100,
      defaultHostControl: this.$store.state.admin.defaultHostControl,
      showMessageDialog: false,
      messageText: '',
      messageType: 'is-primary'
    };
  },
  methods: {
    ShowMessage(){
      this.messageText = '';
      this.messageType = 'is-primary';
      this.showMessageDialog = true;
    },
    SendMessage() {
      this.$socket.client.emit("sendMessage", {
        msg: this.messageText,
        type: this.messageType
      });

      this.showMessageDialog = false;
    },
    RequestUsersVerify(){
      this.$socket.client.emit("requestUsersVerify");
      this.$buefy.notification.open({
        type: "is-primary",
        message: "Verification sent"
      });
    },
    async ResetPlaylist() {
      this.$store.commit("StartLoading")
      const resetUrl = process.env.VUE_APP_API_BASE + `/api/playlists/${this.selectedPlaylist}/reset`;
      console.log(resetUrl)
      const res = await axios.put(resetUrl, {},
        {
          headers: {
            Authorization: "JWT " + this.$store.state.user.token,
          },
        });
      console.log(res)
      this.$store.commit("StopLoading");
      this.$forceUpdate();
      if (res.status == 200) {

        this.$buefy.notification.open({
            type: "is-primary",
            message: this.$t('notifications.playlistReseted')
        });

        this.$sfxPlay('positivenotification')
      } else {

        this.$buefy.notification.open({
            type: "is-danger",
            message: this.$t('notifications.errorResetingExperience')
        });

        this.$sfxPlay('negativenotification')
      }
    },
    RestartScreen(item) {
      this.$buefy.dialog.confirm({
        message: this.$t('notifications.restartExperience',[item.experience.adminTitle,item.space.title]),
        hasIcon: true,
        icon: "power-off",
        iconPack: "fa",
        onConfirm: () => console.log("TODO emit restart")
      });
    },
    PlayersInSpace(spaceSlug) {
      return this.$store.state.admin.players.filter((x) => x.space == spaceSlug);
    },
    InvitePlayersToSpace(spaceId) {
      console.log(spaceId);
      this.$socket.client.emit("invitePlayersToSpace", { spaceId });
    },
    ShowOverrideSpaceExperience(item) {
      this.spaceEditing = item.space;
      this.selectedExperience = item.experience.id;
      this.ShowDialogOverrideExperience = true;
    },
    HideOverrideSpaceExperience() {
      this.spaceEditing = null;
      this.ShowDialogOverrideExperience = false;
    },
    OverrideSpaceExperience() {
      console.log("Overriding experience...", this.spaceEditing, this.selectedExperience);
    },
    ChangePlaylist() {
      console.log("Changing to playlist ID", this.selectedPlaylist);
      this.$store.commit("StartLoading")
      axios.put(process.env.VUE_APP_API_BASE + "/api/globals/setup/setplaylist",
        {
          playlist: this.selectedPlaylist,
        }, {
        headers: {
          Authorization: "JWT " + this.$store.state.user.token,
        },
      })
        .then((res) => {
          console.log(res)
          this.$store.commit("StopLoading");
          this.$forceUpdate();
          if (res.status == 200) {
            this.$buefy.notification.open({
              type: "is-primary",
              message: this.$t('notifications.playlistChanged')
            })
            this.$sfxPlay('positivenotification')
          } else {
            this.$buefy.notification.open({
              type: "is-danger",
              message: this.$t('notifications.errorChangingPlaylist')
            })
            this.$sfxPlay('negativenotification')
          }
        })
        .catch((err) => {
          this.$store.commit("StopLoading");
          console.warn("Error on set playlist", err)
          this.$buefy.notification.open({
            type: "is-danger",
            message: this.$t('notifications.errorChangingPlaylist')
          })
          this.$sfxPlay('negativenotification')
        })
    },
  },
  computed: {
    playlist() {
      return this.$store.getters.remoteItems;
    },
    ShowMe() {
      return this.$store.state.admin.showRemote;
    },
  },
  watch: {
    defaultHostControl(){
      this.$store.commit('admin/setDefaultHostControl', this.defaultHostControl) 
    },
    ShowMe(show) {
      const currScreen = this.$store.state.user.space

      // Scroll to element current space
      if (show && currScreen != 'menu') {
        // Scroll a pantalla actual
        Vue.nextTick(() => {
          const refName = "adminRemote-" + currScreen;
          const el = this.$refs[refName][0].$el
          if (el) {
            this.$refs["adminRemoteModal"].scrollTo({ top: el.offsetTop - 60, behavior: 'smooth' })
          }
        });

      }
    }
  },
  mounted(){

  },
};
</script>

<styles lang="scss" scoped>
#adminRemote {
  .experience-button {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

@media screen and (max-width: 769px){

  .columns {
    margin-top: 1em;
  }
  .column {
    padding: 0px;
    margin-bottom: 1em;
  }

}

@media screen and (min-width: 770px) {
  .playlist-card {
    min-height: 149px;
  }
}
</styles>